import React from 'react';
import { Link } from 'gatsby';
import classes from './index.module.scss';
import BlockedIcon from '../../assets/img/icons/ic-account-blocked.svg';
import { CONTACTS_LINK } from '../../constans/links';

const BlockedUser = () => (
  <div className="blue-grad-bg center-vertical-page-wrapper">
    <div
      className={`${classes.container} auth-verif-container center-content-vertical-mobile container-bordered auth-wrapper`}
    >
      <BlockedIcon className={`${classes.blockedIcon} auth-verif-icon`} />
      <div className={`${classes.title} auth-verif-title t-500`}>
        Your account was blocked!
      </div>
      <Link
        to={CONTACTS_LINK}
        className="auth-verif-btn btn btn_md btn-uppercased t-600 btn_block btn btn_common"
      >
        Contact administrator
      </Link>
    </div>
  </div>
);

export default BlockedUser;
